import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import FlexBox from 'src/components/common/flex-box/FlexBox';

import Card from './components/card/Card';

const StyledSection = styled(Section)`
  padding: ${({ isMobile }) => (isMobile ? '5rem 1.6rem' : '5rem 0')};
`;

const FeaturesWrapper = styled(FlexBox)`
  border-bottom: 0.1rem solid var(--purple-lighter);
  overflow-x: scroll;
`;

const Features = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.features.title', { returnObjects: true });
  const features = t('sections.features.features', { returnObjects: true });

  const titleComponentProps = isMobile
    ? null
    : {
        css: css`
          font-size: 3.6rem !important;
          margin-bottom: 3rem;
          max-width: 70%;
        `,
      };

  const highlightComponentProps = isMobile
    ? null
    : {
        css: css`
          font-size: 3.6rem;
        `,
      };

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    titleComponentProps,
    highlightComponent: HighlightedText,
    highlightComponentProps,
    text: title,
  });

  return (
    <StyledSection isMobile={isMobile}>
      <FlexBox width={isMobile ? '100%' : '60%'}>{renderTitle()}</FlexBox>
      <FeaturesWrapper wrap="nowrap">
        {features.map(({ icon, title, text }, index) => (
          <Card key={`card-${index}`} icon={icon} title={title} text={text} />
        ))}
      </FeaturesWrapper>
    </StyledSection>
  );
};

export default Features;
