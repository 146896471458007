import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import Text from 'src/components/common/text/Text';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import PartnerLogo from 'src/components/common/partner-logo/PartnerLogo';

const StyledSection = styled(Section)`
  background-color: var(--turquoise);
  padding: 5.9rem 2rem;
`;

const StyledText = styled(Text)`
  color: var(--gray-darker);
  margin-top: 1rem;
  text-align: center;
  padding: ${({ isMobile }) => (isMobile ? '2rem 1rem' : 'auto')};
`;

const Grid = styled.div`
  display: grid;
  column-gap: 2.4rem;
  grid-template-columns: ${({ column }) =>
    column ? `repeat(10, ${column / 2}px)` : 'repeat(10, 50px)'};
  grid-template-rows: ${({ row }) =>
    row ? `repeat(6 , ${row / 2}px)` : 'repeat(6, 50px)'};
  margin: 7.5rem 0 6rem 0;
  row-gap: 2.4rem;
  width: 100%;
`;

const StyledFlexBox = styled(FlexBox)`
  margin: 1rem;
  row-gap: 1.4rem;
`;

const PartnerLogoWrapper = styled(FlexBox)`
  background: var(--white);
  border-radius: 1.6rem;
  padding: 2.5rem;
  ${({ width }) => width && `width: ${width};`}
`;

const gridAreas = {
  cdb: css`
    grid-column: 5 / 7;
    grid-row: 1 / 3;
  `,
  labiExames: css`
    grid-column: 3 / 5;
    grid-row: 2 / 4;
  `,
  nabucoLopes: css`
    grid-column: 7 / 9;
    grid-row: 2 / 4;
  `,
  uninove: css`
    grid-column: 1 / 3;
    grid-row: 3 / 5;
  `,
  ePharma: css`
    grid-column: 5 / 7;
    grid-row: 3 / 5;
  `,
  multiscan: css`
    grid-column: 9 / 11;
    grid-row: 3 / 5;
  `,
  sonimed: css`
    grid-column: 3 / 5;
    grid-row: 4 / 6;
  `,
  alemMedicina: css`
    grid-column: 7 / 9;
    grid-row: 4 / 6;
  `,
  plani: css`
    grid-column: 5 / 7;
    grid-row: 5 / 7;
  `,
};

const Partners = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.partners.title', { returnObjects: true });
  const text = t('sections.partners.text');
  const partners = t('sections.partners.partners', { returnObjects: true });

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    titleComponentProps: {
      css: isMobile
        ? css`
            text-align: left;
          `
        : css`
            text-align: center;
          `,
    },
    highlightComponent: HighlightedText,
    text: title,
  });

  const renderGrid = () => (
    <Grid column="170" row="96">
      {partners.map((partner) => (
        <PartnerLogoWrapper
          key={partner}
          css={gridAreas[partner]}
          alignItems="center"
          justifyContent="space-between"
        >
          <PartnerLogo partner={partner} height="100%" width="100%" />
        </PartnerLogoWrapper>
      ))}
    </Grid>
  );

  const renderFlexbox = () => (
    <StyledFlexBox
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      {partners.map((partner) => (
        <PartnerLogoWrapper
          key={partner}
          alignItems="center"
          alignSelf="stretch"
          justifyContent="center"
          width="30%"
        >
          <PartnerLogo partner={partner} height="100%" width="100%" />
        </PartnerLogoWrapper>
      ))}
    </StyledFlexBox>
  );

  return (
    <StyledSection>
      {renderTitle()}
      <StyledText isMobile={isMobile}>{text}</StyledText>
      {isMobile ? renderFlexbox() : renderGrid()}
    </StyledSection>
  );
};

export default Partners;
