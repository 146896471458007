import http from 'src/service/httpConfig';

const medicalSpecialties = ({
  healthPlacePartner = false,
  hasDoctor = false,
  hasDoctorAppIntegration = false,
  isTelemedicine = false,
  isFaceToFace = false,
}) => {
  const filters = [];
  filters.push('itensPerPage=all');
  if (healthPlacePartner)
    filters.push(`healthPlacePartner=${healthPlacePartner}`);
  if (hasDoctor) filters.push('hasDoctor=1');
  if (hasDoctorAppIntegration) filters.push('hasDoctorAppIntegration=1');

  const filterString = filters.reduce((acc, filter) => {
    if (acc === '?') return `${acc}${filter}`;
    return `${acc}&${filter}`;
  }, '?');

  const endpoint = `/medical_specialty${filterString}`;

  const getters = [];
  const createGetterFn = (type) => {
    const url = `${endpoint}&filterAnd[do.type]=${type}`;
    return new Promise((resolve, reject) => {
      http
        .get(url)
        .then((res) => {
          const specialties = res.data.data.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });

          resolve(
            specialties.map((specialty) => ({
              ...specialty,
              modality: type === 'TELEMEDICINE' ? 'TELEMEDICINE' : 'FACETOFACE',
            })),
          );
        })
        .catch(reject);
    });
  };

  if (isTelemedicine) getters.push(createGetterFn('TELEMEDICINE'));
  if (isFaceToFace) getters.push(createGetterFn('FACETOFACE'));

  const getSpecialties = async () =>
    new Promise((resolve, reject) => {
      Promise.all(getters)
        .then((values) => {
          resolve(values.flat());
        })
        .catch(reject);
    });

  return { getSpecialties };
};

export default medicalSpecialties;
