import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import FlexBox from 'src/components/common/flex-box/FlexBox';

import Step from './components/step/Step';

const StyledSection = styled(Section)`
  padding: 5rem 2rem;
`;

const TitleWrapper = styled(FlexBox)`
  margin: 2rem auto;
`;

const StepsWrapper = styled(FlexBox)`
  padding: 3rem 0;
`;

const Steps = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.steps.title', { returnObjects: true });
  const steps = t('sections.steps.steps', { returnObjects: true });

  const titleComponentProps = {
    css: css`
      text-align: center;
    `,
  };

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    titleComponentProps,
    highlightComponent: HighlightedText,
    text: title,
  });

  return (
    <StyledSection>
      <TitleWrapper width={isMobile ? '100%' : '70%'}>
        {renderTitle()}
      </TitleWrapper>
      <StepsWrapper
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : 'space-between'}
        gap="3rem"
        width="100%"
      >
        {steps.map(({ title, text }, index) => (
          <Step
            key={`step-${index}`}
            step={index + 1}
            title={title}
            text={text}
          />
        ))}
      </StepsWrapper>
    </StyledSection>
  );
};

export default Steps;
