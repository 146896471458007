import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import Text from 'src/components/common/text/Text';

const Number = styled(FlexBox)`
  background-color: var(--orange-lighter);
  border-radius: 100%;
  color: var(--orange);
  font-size: 4.1rem;
  font-weight: 700;
  height: 6.2rem;
`;

const Step = ({ step, title, text, ...remainingProps }) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="2rem"
      width={isMobile ? '100%' : '33%'}
      {...remainingProps}
    >
      <Number alignItems="center" justifyContent="center" width="6.2rem">
        {step}
      </Number>
      <Subtitle purple>{title}</Subtitle>
      <Text align="center">{text}</Text>
    </FlexBox>
  );
};

Step.propTypes = {
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Step;
