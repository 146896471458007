import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import FlexBox from 'src/components/common/flex-box/FlexBox';

import Card from './components/card/Card';

const DesktopWrapper = styled(FlexBox)`
  background-color: var(--purple-lighter);
  border-radius: 1.6rem;
  padding: 6.7rem 7.5rem;
`;

const MobileWrapper = styled(FlexBox)`
  padding: 2rem;
`;

const SellingPoints = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.selling_points.title', { returnObjects: true });
  const sellingPoints = t('sections.selling_points.points', {
    returnObjects: true,
  });

  const titleComponentProps = isMobile
    ? null
    : {
        css: css`
          font-size: 3.6rem !important;
          margin-bottom: 3rem;
          max-width: 70%;
        `,
      };

  const highlightComponentProps = isMobile
    ? null
    : {
        css: css`
          font-size: 3.6rem;
        `,
      };

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    titleComponentProps,
    highlightComponent: HighlightedText,
    highlightComponentProps,
    text: title,
  });

  const mobileFlexStyle = css`
    height: auto;
    overflow-x: auto;
  `;

  const renderSellingPoints = () => (
    <FlexBox
      id="prices"
      alignItems="stretch"
      flexWrap={isMobile ? 'nowrap' : 'wrap'}
      justifyContent={isMobile ? 'flex-start' : 'space-between'}
      gap={isMobile ? '1.6rem' : '2rem'}
      width="100%"
      css={isMobile && mobileFlexStyle}
    >
      {sellingPoints.map(({ icon, title, text, price }, index) => (
        <Card
          isMobile={isMobile}
          key={index}
          icon={icon}
          title={title}
          text={text}
          price={price?.price}
          from={price?.from}
          installments={price?.installments}
          width={isMobile ? '90%' : '31%'}
        />
      ))}
    </FlexBox>
  );

  return (
    <Section>
      {isMobile ? (
        <MobileWrapper flexDirection="column" gap="2rem">
          <>{renderTitle()}</>
          <>{renderSellingPoints()}</>
        </MobileWrapper>
      ) : (
        <DesktopWrapper flexDirection="column">
          <>{renderTitle()}</>
          <>{renderSellingPoints()}</>
        </DesktopWrapper>
      )}
    </Section>
  );
};

export default SellingPoints;
