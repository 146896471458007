import React, { useState } from 'react';
import styled from '@emotion/styled';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Text from 'src/components/common/text/Text';

const Label = styled.label`
  align-items: center;
  background-color: ${({ isChecked }) =>
    isChecked ? 'var(--orange)' : 'transparent'};
  color: white;
  display: flex;
  gap: 1rem;
  isolation: isolate;
  justify-content: flex-start;
  margin: 0 0 0 1.5rem;
  padding: 0.3rem 0rem;
  position: relative;
  transition: all ease 120ms;
  z-index: 3;

  &:after,
  &:before {
    background-color: ${({ isChecked }) =>
      isChecked ? 'var(--orange)' : 'transparent'};
    border-radius: 50%;
    content: '';
    display: grid;
    height: 100%;
    place-content: center;
    position: absolute;
    right: -1.5rem;
    transition: all ease 120ms;
    width: 3rem;
    z-index: -20;
  }

  &:after {
    right: -1.6rem;
  }

  &:before {
    left: -1.5rem;
  }
`;

const RadioButton = styled.input`
  appearance: none;
  background-color: transparent;
  border: 0.2rem solid var(--white);
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  height: 2rem;
  margin: 0;
  place-content: center;
  width: 2rem;

  &:before {
    background-color: transparent;
    border-radius: 50%;
    box-shadow: inset 1rem 1rem var(--gray);
    content: '';
    height: 1rem;
    transform: scale(0);
    transition: transform ease-in-out 120ms;
    width: 1rem;
  }

  &:checked {
    border-color: var(--purple);

    &:before {
      box-shadow: inset 1rem 1rem var(--purple);
      transform: scale(1);
    }
  }
`;
RadioButton.defaultProps = { type: 'radio' };

const LabelText = styled(Text)`
  color: var(--white);
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
`;

const RadioButtonGroup = ({
  options,
  selected,
  onChange = () => null,
  ...remainingProps
}) => {
  const [selectedOption, setSelectedOption] = useState({ ...selected });
  const radioButtonName = uniqid();

  const handleOnChange = (index) => {
    setSelectedOption({ ...options[index] });
    onChange?.({ ...options[index] });
  };

  return (
    <FlexBox
      alignItems="center"
      justifyContent="flex-start"
      gap="2rem"
      {...remainingProps}
    >
      {options.map(({ value, label }, index) => (
        <Label
          key={`label-${index}`}
          id={`label-${index}`}
          isChecked={value === selectedOption.value}
        >
          <RadioButton
            name={radioButtonName}
            value={value}
            checked={value === selectedOption.value}
            onChange={() => handleOnChange(index)}
          />
          <LabelText>{label}</LabelText>
        </Label>
      ))}
    </FlexBox>
  );
};

const option = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

RadioButtonGroup.propTypes = {
  options: PropTypes.arrayOf(option),
  selected: option,
  onChange: PropTypes.func,
};

export default RadioButtonGroup;
