import React from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import Text from 'src/components/common/text/Text';
import LinkAsButton from 'src/components/common/link-as-button/LinkAsButton';
import HighlightedImage from 'src/components/common/highlighted-image/HighlightedImage';

import doctorImage from 'src/images/doctor/drconecta-doctor-hero.png';

const StyledSection = styled(Section)`
  ${({ isMobile }) => isMobile && 'padding: 4rem;'}
`;

const Column = styled(FlexBox)`
  margin: ${({ isMobile }) => (isMobile ? '3rem 0' : '10rem 0')};
`;

const StyledText = styled(Text)`
  color: var(--gray);
  margin-top: 3rem;
  padding: 0;
`;

const StyledLinkAsButton = styled(LinkAsButton)`
  margin-top: 3rem;
  padding-left: 6rem;
  padding-right: 6rem;
`;

const PatientDoctor = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t('sections.patient_doctor.title', { returnObjects: true });
  const subtitle = t('sections.patient_doctor.subtitle');
  const text = t('sections.patient_doctor.text', { returnObjects: true });
  const { text: ctaText, link: ctaLink } = t(
    'sections.patient_doctor.call_to_action',
    { returnObjects: true },
  );

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: title,
  });

  return (
    <StyledSection isMobile={isMobile}>
      <FlexBox
        direction={isMobile ? 'column' : 'row'}
        alignSelf="stretch"
        justifyContent="space-between"
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        width="100%"
      >
        <Column
          isMobile={isMobile}
          flexDirection="column"
          alignContent="center"
          alignItems="flex-start"
          justifyContent="center"
          order={isMobile ? 2 : 1}
          width={isMobile ? '100%' : '45%'}
        >
          <Subtitle orange>{subtitle}</Subtitle>
          {renderTitle()}
          {text.map((item, index) => (
            <StyledText key={`text-${index}`}>{item}</StyledText>
          ))}
          <StyledLinkAsButton to={ctaLink}>{ctaText}</StyledLinkAsButton>
        </Column>
        <Column
          isMobile={isMobile}
          alignItems="center"
          alignSelf="stretch"
          justifyContent="center"
          order={isMobile ? 1 : 2}
          width={isMobile ? '100%' : '50%'}
        >
          <HighlightedImage
            purple
            source={doctorImage}
            isResponsive={isMobile}
            width="506"
            height="570"
          />
        </Column>
      </FlexBox>
    </StyledSection>
  );
};

export default PatientDoctor;
