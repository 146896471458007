import React, { createContext, useState, useEffect } from 'react';

import partnerService from 'src/service/partner';

import useLoading from 'src/hooks/useLoading';

export const PartnerContext = createContext();

export const PartnerContextProvider = ({ children }) => {
  const [partner, setPartner] = useState();
  const [isTelemedicine, setIsTelemedicine] = useState(false);
  const [isFaceToFace, setIsFaceToFace] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { setIsLoading } = useLoading();

  useEffect(() => {
    setIsLoading(true);
    partnerService(process.env.GATSBY_FRONTEND_URL)
      .getPartner()
      .then((data) => {
        const { uuid, type } = data;

        if (/telemedicine/gim.test(type)) setIsTelemedicine(true);
        if (/presential/gim.test(type)) setIsFaceToFace(true);

        setPartner(uuid);
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
        setHasLoaded(true);
      });
  }, []);

  return (
    <PartnerContext.Provider
      value={{ partner, isTelemedicine, isFaceToFace, hasLoaded }}
    >
      {children}
    </PartnerContext.Provider>
  );
};
