import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';

const Wrapper = styled(FlexBox)`
  background-color: var(--purple-pill);
  border-radius: 0.8rem;
  color: var(--white);
  cursor: pointer;
  font-size: ${({ isMobile }) => (isMobile ? '1.2rem' : '1.4rem')};
  padding: ${({ isMobile }) => (isMobile ? '0.6rem 0.8rem' : '0.9rem 1rem')};
  transition: 0.4s all ease;

  &:hover {
    background-color: var(--purple-pill-hover);
    transition: 0.4s all ease;
  }
`;

const Pill = ({ children, returnValue, onSelect, ...remainingProps }) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <Wrapper
      alignItems="center"
      justifyContent="center"
      isMobile={isMobile}
      onClick={() => onSelect(returnValue)}
      {...remainingProps}
    >
      {children}
    </Wrapper>
  );
};

Pill.propTypes = {
  returnValue: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onSelect: PropTypes.func,
};

export default Pill;
