import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import debounce from 'src/helpers/debounce';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Link from 'src/components/common/link/Link';
import Text from 'src/components/common/text/Text';

import searchIcon from './images/search-icon.svg';

const Wrapper = styled(FlexBox)`
  isolation: isolate;
  position: relative;
  width: 100%;
`;

const List = styled.ul`
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem var(--purple-dropshadow);
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 0.5rem;
  height: auto;
  isolation: isolate;
  justify-content: flex-start;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: 29.5rem;
  overflow-x: scroll;
  padding: 0;
  position: absolute;
  top: calc(100% + 0.05rem);
  width: 100%;
  z-index: 100;
`;

const Item = styled.li`
  line-height: 2;
  margin: 0;
  padding: 1rem 3rem;
  width: 100%;
  z-index: 2;

  &:not(:last-of-type) {
    border-bottom: 0.1rem solid var(--purple-dropshadow);
  }

  &:last-of-type {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  &:hover {
    background: var(--orange);
    color: var(--white);
  }
`;

const Input = styled.input`
  align-self: stretch;
  background: var(--white);
  border: 0;
  color: var(--grey-darker);
  height: 4.6rem;
  font-size: ${({ isMobile }) => (isMobile ? '1.2rem' : '1.6rem')};
  margin: 0 0 0 -2.4rem;
  padding: ${({ isMobile }) =>
    isMobile ? '0.5rem 3rem 0.5rem 1rem' : '1rem 3rem 1rem 1rem'};
  position: relative;
  width: calc(100% - 4.6rem);
  outline: none;
`;

const LeftCircle = styled.div`
  align-self: stretch;
  background-color: var(--white);
  border-radius: 50%;
  height: 4.6rem;
  width: 4.6rem;
  z-index: -1;
`;

const RightCircle = styled(FlexBox)`
  align-self: stretch;
  background-color: var(--white);
  border-radius: 50%;
  margin-left: -2rem;
  height: 100%;
  padding: 0.2rem;
  width: 4.8rem;
  z-index: 3;
`;

const NotFoundText = styled(Text)`
  font-size: 1.4rem;
`;

const Autocomplete = ({ placeholder, list, selected, onSelect, ...remainingProps }) => {
  const ref = useRef('list');
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();
  const [showList, setShowList] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [localList, setLocalList] = useState([]);
  const [makeLinkData, setMakeLinkData] = useState(null)

  useEffect(() => {
    setLocalList(list);
  }, [list]);

  useEffect(() => {

    if(!selected) return

    const hasItem = list.filter(({ name }) =>
      name.toLowerCase().includes(inputValue.toLowerCase()),
    )

    if (hasItem.length === 0) {
      setInputValue('')
      return;
    };
    setMakeLinkData({...makeLinkData, modality: hasItem[0].modality})

  }, [selected, localList, list]);



  useEffect(() => {
    if (inputValue) {
      onSelect(inputValue)
      debounce(setLocalList)(
        list.filter(({ name }) =>
          name.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      );
    } else {
      setLocalList(list);
    }
  }, [inputValue]);

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (showList && ref.current && !ref.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showList]);

  const makeLink = (id, modality) => {
    const url = new URL(process.env.GATSBY_DESTINATION_URL);
    url.searchParams.set('t', modality === 'TELEMEDICINE' ? 1 : 2);
    url.searchParams.set('ms', id);

    return url.toString();
  };

  return (
    <Wrapper alignItems="center" gap="0" {...remainingProps}>
      {/* <LeftCircle /> */}
      <Input
        type="text"
        isMobile={isMobile}
        placeholder={placeholder}
        value={inputValue}
        onChange={(event) => {
          setInputValue(event.target.value)
          if (event.target.value === '') onSelect('')
        }}
        onFocus={() => setShowList(true)}
        style={{borderRadius: '2em', paddingLeft: '2em', marginLeft: '0.2em'}}
      />

          {inputValue &&
            <img
              style={{cursor: 'pointer', position: 'absolute', right: '45px'}}
              onClick={() => {
                const { id, modality } = makeLinkData
                window.location.href = makeLink(id, modality)
              }}
              src={searchIcon}
            />
          }
        {/* <RightCircle alignItems="center" justifyContent="center">

        </RightCircle> */}
      {showList && (
        <List ref={ref}>
          {localList && localList.length > 0 ? (
            localList.map(({ id, name, modality }, index) => (
              <Item
                key={`list-item-${index}`}
                onClick={() => {
                  setInputValue(name)
                  setShowList(false)
                  setMakeLinkData({id, modality})
                }}
              >
                {name}
              </Item>
            ))
          ) : (
            <Item>
              <NotFoundText>{t('sections.hero.not_found')}</NotFoundText>
            </Item>
          )}
        </List>
      )}
    </Wrapper>
  );
};

Autocomplete.propTypes = {
  placeholder: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      value: PropTypes.string,
    }).isRequired,
  ),
  onSelect: PropTypes.func,
};

export default Autocomplete;
