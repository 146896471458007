import React from 'react';
import styled from '@emotion/styled';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Section from 'src/components/common/section/Section';

import MobileDownloadApp from './components/mobile-download-app/MobileDownloadApp';
import DesktopDownloadApp from './components/desktop-download-app/DesktopDownloadApp';

const StyledSection = styled(Section)`
  background-color: ${({ isMobile }) =>
    isMobile ? 'var(--orange-lighter)' : 'var(--white)'};
  padding: 4rem 2.4rem;
`;

const DownloadApp = () => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <StyledSection isMobile={isMobile}>
      {isMobile ? <MobileDownloadApp /> : <DesktopDownloadApp />}
    </StyledSection>
  );
};

export default DownloadApp;
