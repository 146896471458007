import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import Text from 'src/components/common/text/Text';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import AppStoreBadge from 'src/components/common/app-store-badge/AppStoreBadge';

import phoneMockup1 from 'src/components/patient/download-app/images/download-app-phone-1.png';
import phoneMockup2 from 'src/components/patient/download-app/images/download-app-phone-2.png';

const MobileGrid = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 40%;
  grid-template-rows: 3rem 1fr 3rem;
  isolation: isolate;
  margin-top: 3rem;
  width: 100%;
`;

const StyledImg = styled.img`
  border: 0;
  object-fit: cover;
`;

const StyledSubtitle = styled(Subtitle)`
  margin: 2rem 0 1rem 0;
`;

const MobileDownloadApp = () => {
  const { t } = useTranslation();

  const title = t('sections.download_app.title', { returnObjects: true });
  const texts = t('sections.download_app.text', { returnObjects: true });
  const callToAction = t('sections.download_app.call_to_action');
  const storeLinks = t('app_store', { returnObjects: true });

  const { render: renderMobileTitleFirstSentence } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: [title[0]],
  });

  const { render: renderMobileTitleSecondSentence } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: [title[1]],
  });

  return (
    <>
      <>
        {renderMobileTitleFirstSentence()}
        <br />
        {renderMobileTitleSecondSentence()}
      </>
      <MobileGrid>
        <FlexBox
          alignItems="flex-start"
          justifyContent="flex-start"
          css={css`
            grid-column: 1 / 3;
            grid-row: 1 / 3;
            z-index: 1;
          `}
        >
          <StyledImg src={phoneMockup1} width="100%" height="auto" />
        </FlexBox>
        <FlexBox
          alignItems="flex-start"
          justifyContent="flex-end"
          css={css`
            grid-column: 2 / 4;
            grid-row: 2 / 4;
            z-index: 0;
          `}
        >
          <StyledImg src={phoneMockup2} width="100%" height="auto" />
        </FlexBox>
      </MobileGrid>
      {texts.map((text, index) => (
        <Text key={`text-${index}`}>{text}</Text>
      ))}
      <StyledSubtitle purple>{callToAction}</StyledSubtitle>
      <FlexBox
        alignItems="center"
        justifyContent="flex-start"
        gap="1.3rem"
        width="100%"
      >
        {Object.keys(storeLinks).map((store) => (
          <AppStoreBadge key={store} type={store} />
        ))}
      </FlexBox>
    </>
  );
};

export default MobileDownloadApp;
