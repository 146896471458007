import React, { useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import http from 'src/service/httpConfig';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import usePartner from 'src/hooks/usePartner';
import useMedicalSpecialties from 'src/hooks/useMedicalSpecialties';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import RadioButtonGroup from 'src/components/common/radio-button-group/RadioButtonGroup';
import Autocomplete from 'src/components/common/autocomplete/Autocomplete';

import SpecialtyList from 'src/components/patient/patient-hero/components/specialty-list/SpecialtyList';

const Box = styled(FlexBox)`
  background-color: var(--purple);
  border-radius: 1.6rem;
  height: 100%;
  isolation: isolate;
  padding: ${({ isMobile }) => (isMobile ? '1.5rem' : '3rem')};
  width: 100%;
  z-index: 3;
`;

const SpecialtySearch = () => {
  const [medicalSpecialties, setMedicalSpecialties] = useState([]);
  const [persistSelected, setPersistSelected] = useState(null)

  const [ preferedAppointments, setPreferedAppointments ] = useState(null)

  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();
  const { hasLoaded, partner } = usePartner();
  const { getMedicalSpecialties } = useMedicalSpecialties();

  const specialtyOptions = t('sections.hero.options', { returnObjects: true });
  const autocompletePlaceholder = t('sections.hero.autocomplete');

  const [modality, setModality] = useState(specialtyOptions[0]);

  useEffect(async () => {
    if (hasLoaded && partner) {
      const specialties = await getMedicalSpecialties();
      setMedicalSpecialties(specialties);
      fetchSpecialties();
    }
  }, [hasLoaded]);

  const fetchSpecialties = useCallback(() => {
    const drConectaUuid = "4bb8ef02-64e5-11eb-bbf5-94f6d617cf3e";
    const uuidPartner = window.location.href.match(/drconecta.com.br/gi) !== null ? drConectaUuid : partner;
    new Promise(resolve => {
      http
        .get(`health_place_partner/${uuidPartner}/medical_specialty/most_chosen`)
        .then((res) => {
          const { data } = res;
          setPreferedAppointments(data.data)
          resolve(data);
        })
        .catch(() => resolve());
    });

  }, [partner])

  const handleChangeOption = (option) => {
    if (modality.value !== option.value) {
      setModality({ ...option });
    }
  };

  return (
    <Box
      isMobile={isMobile}
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      gap="1rem"
    >
      <RadioButtonGroup
        css={css`
          align-self: stretch;
          width: 100%;
        `}
        options={specialtyOptions}
        selected={modality}
        onChange={handleChangeOption}
      />
      <Autocomplete
        placeholder={autocompletePlaceholder}
        list={
          medicalSpecialties
            ? medicalSpecialties.filter(
                specialty => specialty.modality === modality.value,
              )
            : []
        }
        onSelect={(data) => setPersistSelected(data)}
        selected={persistSelected}
      />
      <SpecialtyList modality={modality} medicalSpecialties={medicalSpecialties} list={preferedAppointments || []} />
    </Box>
  );
};

export default SpecialtySearch;
