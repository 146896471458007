import React from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import useRenderTitle from 'src/hooks/useRenderTitle';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Logo from 'src/components/common/logo/Logo';
import Link from 'src/components/common/link/Link';
import LinkAsButton from 'src/components/common/link-as-button/LinkAsButton';
import LinkAsDropdown from 'src/components/common/link-as-dropdown/LinkAsDropdown';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import HighlightedImage from 'src/components/common/highlighted-image/HighlightedImage';
import PartnerLogo from 'src/components/common/partner-logo/PartnerLogo';

import SpecialtySearch from 'src/components/patient/patient-hero/components/specialty-search/SpecialtySearch';

import heroImage from 'src/images/patient/drconecta-patient-hero.png';

const Row = styled(FlexBox)`
  padding: 3.8rem 0 5rem 0;
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  gap: 4rem;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  margin: 0;
  padding: 0;
`;

const StyledLink = styled(Link)`
  font-size: 1.4rem;
  font-weight: 600;
`;

const StyledLinkAsButton = styled(LinkAsButton)`
  padding-left: 4rem;
  padding-right: 4rem;
`;

const StyledLinkAsDropdown = styled(LinkAsDropdown)`
  padding-left: 4rem;
  padding-right: 4rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 55% 10% 35%;
  grid-template-rows: 3fr 25rem 1fr;
`;

const TextWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 1rem;
`;

const SpecialtyWrapper = styled.div`
  border-radius: 1.6rem;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  padding: 0;
  margin-bottom: -15px;
`;

const HighlightedImageWrapper = styled.div`
  grid-column: 2 / 4;
  grid-row: 1 / 4;
  z-index: -1;
`;

const PartnersWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  z-index: -1;
`;

const DesktopPatientHero = () => {
  const { t } = useTranslation();

  const title = t('sections.hero.title', { returnObjects: true });
  const subtitle = t('sections.hero.subtitle');
  const links = t('sections.hero.links', { returnObjects: true });
  const partners = t('sections.hero.partners', { returnObjects: true });

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: title,
  });

  return (
    <>
      <Row alignItems="center" justifyContent="space-between" width="100%">
        <Logo type="logo" height="auto" width="220" />
        <List>
            {links.map(({ link, name, type, sub_links, tertiary }, index) => (
              <Item key={`link-${index}`}>
                {type === 'link' ? (
                  <StyledLink to={link}>{name}</StyledLink>
                ) : false}

                {type === 'linkAsButton' ? (
                  <StyledLinkAsButton to={link}>{name}</StyledLinkAsButton>
                ) : false}

                {type === 'linkAsDropdown' ? (
                    <StyledLinkAsDropdown to={link} sub_links={sub_links} tertiary={tertiary}>
                      {name}
                    </StyledLinkAsDropdown>
                ) : false}
              </Item>
            ))}
          </List>
      </Row>
      <Grid>
        <TextWrapper>
          <StyledSubtitle orange>{subtitle}</StyledSubtitle>
          {renderTitle()}
        </TextWrapper>
        <SpecialtyWrapper>
          <SpecialtySearch />
        </SpecialtyWrapper>
        <HighlightedImageWrapper>
          <HighlightedImage
            orange
            isResponsive
            source={heroImage}
            width="100%"
            height="auto"
          />
        </HighlightedImageWrapper>
        <PartnersWrapper>
          <FlexBox
            alignItems="flex-end"
            justifyContent="space-evenly"
            height="100%"
            width="100%"
          >
            {partners.map((partner, index) => (
              <PartnerLogo
                key={`partner-${index}`}
                grayscale
                partner={partner}
                height="28rem"
                width="auto"
              />
            ))}
          </FlexBox>
        </PartnersWrapper>
      </Grid>
    </>
  );
};

export default DesktopPatientHero;
