import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Text from 'src/components/common/text/Text';
import Icon from 'src/components/common/icon/Icon';
import Pill from 'src/components/common/pill/Pill';
import { useEffect } from 'react';

const Title = styled(Text)`
  color: var(--white);
  cursor: ${({ isMobile }) => (isMobile ? 'pointer' : 'default')};
  font-size: 1.3rem;
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 0.5rem;
  margin-bottom: 0.2rem;
`;

const DesktopSpecialties = styled(FlexBox)`
  padding-bottom: 1rem;
  overflow: hidden;
`;

const MobileSpecialties = styled.div`
  height: 0;
  margin-top: -1rem;
  overflow: hidden;
  width: 100%;
  color: white;
`;

const MotionMobileSpecialties = motion(MobileSpecialties);

const SpecialtyList = ({ list, onSelect, medicalSpecialties, modality,  ...remainingProps }) => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const title = t('sections.hero.frequently_searched');

  const localList = useMemo(() => {
    return list.splice(0,5);
  }, [isMobile, list, medicalSpecialties, modality])

  const makeLink = (id, modality) => {
    const url = new URL(process.env.GATSBY_DESTINATION_URL);
    url.searchParams.set('t', modality === 'TELEMEDICINE' ? 1 : 2);
    url.searchParams.set('ms', id);

    return url.toString();
  };

  const renderPills = () =>
    localList.map(({ name, returnValue, modality }, index) => (
      <Pill
        key={`pill-specialty-${index}`}
        returnValue={returnValue}
        value={name}
        onClick={ () => {
          const hasSpecialty = medicalSpecialties.filter(spe => { return spe.modality === modality}).filter( item => { return item.name === name})

          const id = hasSpecialty.length > 0 ? hasSpecialty[0].id : null

          if(!id) return
          window.location.href = makeLink(id, modality)
        }}
      >
        {name}
      </Pill>
    ));

  return (
    localList.length === 0 ? <></> :
    <FlexBox
      flexDirection="column"
      gap="1.3rem"
      width="100%"
      {...remainingProps}
    >
      <Title
        isMobile={isMobile}
        align={isMobile ? 'center' : 'left'}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {title}
        {isMobile && (
          <StyledIcon
            as={motion.img}
            initial={false}
            transition={{ ease: 'easeOut', duration: 0.3 }}
            animate={
              isCollapsed
                ? { transform: 'rotate(0deg)' }
                : { transform: 'rotate(180deg)' }
            }
            icon="chevron"
            height="4"
            width="8"
          />
        )}
      </Title>
      {isMobile && localList && (
        <MotionMobileSpecialties
          initial={false}
          transition={{ ease: 'easeOut', duration: 0.3 }}
          animate={isCollapsed ? { height: '0' } : { height: 'auto' }}
          height="0"
        >
          <FlexBox
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            gap="1rem"
            width="100%"
          >
            {renderPills()}
          </FlexBox>
        </MotionMobileSpecialties>
      )}
      {!isMobile && localList && (
        <FlexBox flexWrap="wrap" gap="1rem" width="100%">
          {renderPills()}
        </FlexBox>
      )}
    </FlexBox>
  );
};

SpecialtyList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      returnValue: PropTypes.any,
      name: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func,
};

export default SpecialtyList;
