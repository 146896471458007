import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import Text from 'src/components/common/text/Text';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import AppStoreBadge from 'src/components/common/app-store-badge/AppStoreBadge';

import phoneMockup1 from 'src/components/patient/download-app/images/download-app-phone-1.png';
import phoneMockup2 from 'src/components/patient/download-app/images/download-app-phone-2.png';

const DesktopGrid = styled.div`
  display: grid;
  grid-template-columns: 55% calc(202px) 3rem calc(202px) 1fr;
  grid-template-rows: 4.7rem 1fr 4.7rem;
  isolation: isolate;
`;

const Background = styled.div`
  background-color: var(--orange-lighter);
  border-radius: 1.6rem;
  grid-column: 1 / 6;
  grid-row: 2 / 3;
  z-index: 0;
`;

const TextWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  padding: 8.7rem 0 8.7rem 8.7rem;
  z-index: 1;
`;

const StyledSubtitle = styled(Subtitle)`
  font-size: 1.8rem;
  margin: 2rem 0 1rem 0;
`;

const StyledImg = styled.img`
  border: 0;
  object-fit: cover;
`;

const DesktopDownloadApp = () => {
  const { t } = useTranslation();

  const title = t('sections.download_app.title', { returnObjects: true });
  const texts = t('sections.download_app.text', { returnObjects: true });
  const callToAction = t('sections.download_app.call_to_action');
  const storeLinks = t('app_store', { returnObjects: true });

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    titleComponentProps: {
      css: css`
        font-size: 3.6rem !important;
        margin-bottom: 2rem;
      `,
    },
    highlightComponent: HighlightedText,
    highlightComponentProps: {
      css: css`
        font-size: 3.6rem !important;
      `,
    },
    text: title,
  });

  return (
    <DesktopGrid>
      <Background />
      <TextWrapper>
        {renderTitle()}
        {texts.map((text, index) => (
          <Text key={`text-${index}`}>{text}</Text>
        ))}
        <StyledSubtitle purple>{callToAction}</StyledSubtitle>
        <FlexBox
          alignItems="center"
          justifyContent="flex-start"
          gap="1.3rem"
          width="100%"
        >
          {Object.keys(storeLinks).map((store) => (
            <AppStoreBadge key={store} type={store} />
          ))}
        </FlexBox>
      </TextWrapper>
      <FlexBox
        alignItems="flex-start"
        justifyContent="flex-start"
        css={css`
          grid-column: 2 / 4;
          grid-row: 1 / 3;
          z-index: 3;
        `}
      >
        <StyledImg src={phoneMockup1} width="auto" height="90%" />
      </FlexBox>
      <FlexBox
        alignItems="flex-end"
        justifyContent="flex-end"
        css={css`
          grid-column: 3 / 5;
          grid-row: 2 / 4;
          z-index: 2;
        `}
      >
        <StyledImg src={phoneMockup2} width="auto" height="90%" />
      </FlexBox>
    </DesktopGrid>
  );
};

export default DesktopDownloadApp;
