import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Icon from 'src/components/common/icon/Icon';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import Text from 'src/components/common/text/Text';

const StyledFlexBox = styled(FlexBox)`
  flex-shrink: 0;
  padding: ${({ isMobile }) => (isMobile ? '2rem 0' : '2rem')};
`;

const Card = ({ icon, title, text, ...remainingProps }) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <StyledFlexBox
      isMobile={isMobile}
      flexDirection="column"
      alignItems="flex-start"
      alignSelf="stretch"
      justifyContent="flex-start"
      gap="2rem"
      width={isMobile ? '100%' : '35%'}
      {...remainingProps}
    >
      <Icon icon={icon} height="3.2rem" width="auto" />
      <Subtitle purple>{title}</Subtitle>
      <Text>{text}</Text>
    </StyledFlexBox>
  );
};

Card.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Card;
