import http from 'src/service/httpConfig';

const partner = (url) => {
  const endpoint = `/health_place_partner/access_url/${url}`;

  const getPartner = () =>
    new Promise((resolve) => {
      http
        .get(endpoint)
        .then((res) => {
          const { data } = res;

          if (data['is_active']) {
            resolve(data);
            return;
          }

          resolve();
        })
        .catch(() => resolve());
    });

  return { getPartner };
};

export default partner;
