import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import disableScroll from 'disable-scroll';

import FlexBox from 'src/components/common/flex-box/FlexBox';

const Wrapper = styled(FlexBox)`
  background-color: var(--purple-highlight);
  inset: 0;
  isolation: isolate;
  position: fixed;
  z-index: 100;
`;

const Spinner = styled.div`
  animation: spin 2s linear infinite;
  border: 0.7rem solid var(--orange);
  border-top-color: var(--orange-shadow);
  border-radius: 50%;
  height: 15rem;
  width: 15rem;
`;

const Loader = () => {
  useEffect(() => {
    disableScroll.on();

    return () => disableScroll.off();
  }, []);

  return ReactDOM.createPortal(
    <Wrapper
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
    >
      <Spinner />
    </Wrapper>,
    document.body,
  );
};

export default Loader;
