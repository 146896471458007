import React from 'react';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Hero from 'src/components/common/hero/Hero';

import MobilePatientHero from './components/mobile-patient-hero/MobilePatientHero';
import DesktopPatientHero from './components/desktop-patient-hero/DesktopPatientHero';

const PatientHero = () => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <Hero id="home">{isMobile ? <MobilePatientHero /> : <DesktopPatientHero />}</Hero>
  );
};

export default PatientHero;
