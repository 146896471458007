import React from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import useRenderTitle from 'src/hooks/useRenderTitle';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Logo from 'src/components/common/logo/Logo';
import Subtitle from 'src/components/common/subtitle/Subtitle';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import HighlightedImage from 'src/components/common/highlighted-image/HighlightedImage';
import PartnerLogo from 'src/components/common/partner-logo/PartnerLogo';

import SpecialtySearch from 'src/components/patient/patient-hero/components/specialty-search/SpecialtySearch';

import heroImage from 'src/images/patient/drconecta-patient-hero.png';

const Wrapper = styled(FlexBox)`
  padding: 2rem;
`;

const StyledSubtitle = styled(Subtitle)`
  margin: 2rem 0 0.5rem 0;
`;

const PartnersWrapper = styled(FlexBox)`
  margin: 3rem 0;
`;

const StyledHighlightedImage = styled(HighlightedImage)`
  margin-top: 2rem;
`;

const MobilePatientHero = () => {
  const { t } = useTranslation();

  const title = t('sections.hero.title', { returnObjects: true });
  const subtitle = t('sections.hero.subtitle');
  const partners = t('sections.hero.partners', { returnObjects: true });

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    highlightComponent: HighlightedText,
    text: title,
  });

  return (
    <Wrapper flexDirection="column">
      <Logo type="patient" height="auto" width="160" />
      <StyledSubtitle orange>{subtitle}</StyledSubtitle>
      {renderTitle()}
      <PartnersWrapper alignItems="center" justifyContent="space-between">
        {partners.map((partner, index) => (
          <PartnerLogo
            key={`partner-${index}`}
            grayscale
            partner={partner}
            height="25rem"
            width="auto"
          />
        ))}
      </PartnersWrapper>
      <SpecialtySearch />
      <StyledHighlightedImage
        orange
        isResponsive
        source={heroImage}
        width="100%"
        height="auto"
      />
    </Wrapper>
  );
};

export default MobilePatientHero;
