import React, { createContext } from 'react';

import medicalSpecialtiesService from 'src/service/medicalSpecialties';

import useLoading from 'src/hooks/useLoading';
import usePartner from 'src/hooks/usePartner';

export const MedicalSpecialtiesContext = createContext();

export const MedicalSpecialtiesContextProvider = ({ children }) => {
  const { partner, isTelemedicine, isFaceToFace, hasLoaded } = usePartner();
  const { setIsLoading } = useLoading();

  const getMedicalSpecialties = async () => {
    setIsLoading(true);

    try {
      if (hasLoaded) {
        const specialties = await medicalSpecialtiesService({
          healthPlacePartner: partner,
          hasDoctor: false,
          hasDoctorAppIntegration: false,
          isTelemedicine,
          isFaceToFace,
        }).getSpecialties();

        return specialties;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MedicalSpecialtiesContext.Provider value={{ getMedicalSpecialties }}>
      {children}
    </MedicalSpecialtiesContext.Provider>
  );
};
