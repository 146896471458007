import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import FlexBox from 'src/components/common/flex-box/FlexBox';

import Card from './components/card/Card';

import desktopBackgroundImage from './images/drconecta-patient-testimonials-bg.png';
import mobileBackgroundImage from './images/drconecta-patient-testimonials-bg-mobile.png';

const StyledSection = styled(Section)`
  background-color: var(--purple);
  background-repeat: no-repeat;
  background-position: ${({ isMobile }) =>
    isMobile ? '95% 5%' : 'calc(100% - 8rem) calc(0% + 6.4rem)'};
  padding: ${({ isMobile }) => (isMobile ? '5.3rem 1.6rem' : '10rem 0 5rem 0')};
`;
  
// border-bottom: 1px solid var(--white);
const TestimonialsWrapper = styled(FlexBox)`
  height: auto;
  margin: ${({ isMobile }) => (isMobile ? '5.3rem 0' : '8.5rem 0 2rem')};
  overflow-x: scroll;
  padding-bottom: 6rem;
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
`;

const TestimonialsControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const TestimonialsControl = styled.button`
  cursor: pointer;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  opacity: 0.6;

  &:first-of-type{
    margin-right: 10px;
  }

  &:hover{
    opacity: 1;
  }
`;

const Testimonials = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const title = t(
    `sections.testimonials.title.${isMobile ? 'mobile' : 'desktop'}`,
    { returnObjects: true },
  );
  const testimonials = t('sections.testimonials.testimonials', {
    returnObjects: true,
  });

  const { render: renderTitle } = useRenderTitle({
    titleComponent: Title,
    titleComponentProps: {
      css: css`
        text-align: left;
      `,
      white: true,
    },
    highlightComponent: HighlightedText,
    highlightComponentProps: { purple: true },
    text: title,
  });

  const handleOnClick = (prev) => {
    const scrollSize = 712;
    const scrollContainer = document.getElementsByClassName("TestimonialsWrapper")[0];
    prev ? scrollContainer.scrollLeft += (scrollSize * -1) : scrollContainer.scrollLeft += scrollSize;
  }

  return (
    <StyledSection
      isMobile={isMobile}
      style={{
        backgroundImage: `url(${
          isMobile ? mobileBackgroundImage : desktopBackgroundImage
        })`,
      }}
    >
      {renderTitle()}
      <TestimonialsWrapper className="TestimonialsWrapper" isMobile={isMobile} flexWrap="nowrap" gap="3rem">
        {testimonials.map(({ text, name, subtitle }, index) => (
          <Card
            key={`testimonial-${index}`}
            text={text}
            name={name}
            subtitle={subtitle}
          />
        ))}
      </TestimonialsWrapper>
      <TestimonialsControlWrapper>
        <TestimonialsControl type='button' onClick={() => handleOnClick(true)}>&larr;</TestimonialsControl>
        <TestimonialsControl type='button' onClick={() => handleOnClick(false)}>&rarr;</TestimonialsControl>
      </TestimonialsControlWrapper>
    </StyledSection>
  );
};

export default Testimonials;
