import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Icon from 'src/components/common/icon/Icon';
import Text from 'src/components/common/text/Text';

const StyledWrapper = styled(FlexBox)`
  background-color: var(--purple-lighter);
  border-radius: 1.6rem;
  flex-shrink: 0;
  padding: ${({ isMobile }) => (isMobile ? '3rem 2.7rem' : '4rem 3.2rem')};
  transition: all 0.4s ease;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      &:hover {
        background-color: var(--white);
        box-shadow: 0 0 1.75rem var(--purple-dropshadow);
        transition: all 0.4s ease;
      }
    `}
`;

const StyledTitle = styled(Text)`
  color: var(--purple);
  font-size: ${({ isMobile }) => (isMobile ? '1.8rem' : '2rem')};
  font-weight: 700;
  margin-top: 2.3rem;
`;

const StyledText = styled(Text)`
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

const InfoWrapper = styled(FlexBox)`
  margin-top: auto;
`;

const From = styled(Text)`
  color: var(--gray-lighter);
  font-size: ${({ isMobile }) => (isMobile ? '1.rem' : '1.6rem')};
`;

const Price = styled(Text)`
  color: var(--orange);
  font-weight: 700;
  font-size: 2.8rem;
`;

const Installments = styled(Text)`
  color: var(--orange);
  font-size: ${({ isMobile }) => (isMobile ? '1.6rem' : '1.4rem')};
`;

const Card = ({
  icon,
  title,
  text,
  from,
  price,
  installments,
  ...remainingProps
}) => {
  const { sm: isMobile } = useBreakpoint();

  const desktopIconStyle = css`
    min-height: 8rem;
  `;

  return (
    <StyledWrapper
      isMobile={isMobile}
      flexDirection="column"
      alignItems="flex-start"
      alignSlef="stretch"
      justifyContent="flex-start"
      {...remainingProps}
    >
      <Icon
        isMobile={isMobile}
        icon={icon}
        height="auto"
        width={isMobile ? '4.5rem' : '6rem'}
        css={!isMobile && desktopIconStyle}
      />
      <StyledTitle isMobile={isMobile}>{title}</StyledTitle>
      <StyledText isMobile={isMobile}>{text}</StyledText>
      <InfoWrapper flexDirection="column">
        {from && <From isMobile={isMobile}>{from}</From>}
        {price && <Price isMobile={isMobile}>{price}</Price>}
        {installments && (
          <Installments isMobile={isMobile}>{installments}</Installments>
        )}
      </InfoWrapper>
    </StyledWrapper>
  );
};

Card.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  from: PropTypes.string,
  price: PropTypes.string,
  installments: PropTypes.string,
};

export default Card;
