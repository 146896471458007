import React from 'react';
import { graphql } from 'gatsby';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { LoadingContextProvider } from 'src/contexts/LoadingContext';
import { PartnerContextProvider } from 'src/contexts/PartnerContext';
import { MedicalSpecialtiesContextProvider } from 'src/contexts/MedicalSpecialtiesContext';

import HeaderTags from 'src/components/common/header-tags/HeaderTags';
import PageWrapper from 'src/components/common/page-wrapper/PageWrapper';
import Header from 'src/components/common/header/Header';
import Faqs from 'src/components/common/faqs/Faqs';
import Footer from 'src/components/common/footer/Footer';

import PatientHero from 'src/components/patient/patient-hero/PatientHero';
import Steps from 'src/components/patient/steps/Steps';
import Features from 'src/components/patient/features/Features';
import SellingPoints from 'src/components/patient/selling-points/SellingPoints';
import DownloadApp from 'src/components/patient/download-app/DownloadApp';
import Testimonials from 'src/components/patient/testimonials/Testimonials';
import Partners from 'src/components/patient/partners/Partners';
import PatientDoctor from 'src/components/patient/patient-doctor/PatientDoctor';

const PatientIndexPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderTags
        title={t('page.title')}
        metaItems={t('page.meta', { returnObjects: true })}
      />
      <LoadingContextProvider>
        <PartnerContextProvider>
          <PageWrapper>
            <Header pageType="patient" />
            <MedicalSpecialtiesContextProvider>
              <PatientHero />
            </MedicalSpecialtiesContextProvider>
            <Steps />
            <Features />
            <SellingPoints />
            <DownloadApp />
            <Testimonials />
            <Faqs
              title={t('sections.faqs.title', { returnObjects: true })}
              faqs={t('sections.faqs.faqs', { returnObjects: true })}
            />
            <Partners />
            <PatientDoctor />
            <Footer />
          </PageWrapper>
        </PartnerContextProvider>
      </LoadingContextProvider>
    </>
  );
};

export default PatientIndexPage;

export const query = graphql`
  query ($language: String! = "pt-BR") {
    locales: allLocale(
      filter: { ns: { in: ["common", "patient"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
